/* eslint-disable radix */
import {
    // features,
    ThirdPartyTracking,
} from '@config';

const addToCartFbPixel = (name = '', price = 0, currency, sku = '', qty) => {
    const fbqAddToCart = () => {
        if (window.fbq) {
            window.fbq('track', 'AddToCart', {
                content_name: name,
                content_ids: [sku],
                content_type: 'product',
                currency,
                value: parseInt(price),
                contents: [{
                    id: sku,
                    quantity: qty,
                }],
            });
        }
    };

    if (ThirdPartyTracking.FBPixel.enabled) fbqAddToCart();

    // if (name.toLowerCase().includes('lg')) {
    //     if (window.gtag) {
    //         window.gtag('event', 'conversion', { send_to: 'AW-339409063/csylCOHNgt8CEKfx66EB' });
    //     }

    //     window.fbq('init', features.facebookLgTracking.id);
    //     fbqAddToCart();
    // }
};

export default addToCartFbPixel;
