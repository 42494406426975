import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

const formatDate = (date = new Date(), format = 'D MMMM YYYY, HH:mm:ss', tzName) => {
    if (tzName) {
        const dateUtc = `${date}Z`;
        return dayjs(dateUtc).tz(tzName).format(format);
    }
    return dayjs(date).format(format);
};

export default formatDate;

// import dayjs from 'dayjs';

// const formatDate = (date = new Date(), format = 'D MMMM YYYY, HH:mm:ss') => dayjs(date).format(format);

// export default formatDate;
