/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { getStoreHost } from '@helpers/config';
import { modules } from '@config';
import Router from 'next/router';
import { getAppEnv } from '@root/core/helpers/env';
import ArrowBack from '@material-ui/icons/ArrowBack';
import Button from '@common_button';
import Typography from '@common_typography';
import { useTranslation } from '@i18n';

const HeaderCheckout = ({
    storeConfig,
}) => {
    const { t } = useTranslation(['checkout']);
    const BackToStore = () => {
        if (modules.checkout.checkoutOnly) {
            window.location.replace(getStoreHost(getAppEnv()));
        } else {
            Router.push('/');
        }
    };
    return (
        <div id="header">
            <Button rootClassName="back-button" customRootStyle={{ position: 'absolute', left: 30, width: 'fit-content' }} onClick={BackToStore}>
                <ArrowBack />
                <Typography variant="span" type="bold" letter="capitalize" color="white">
                    {t('backToHome')}
                </Typography>
            </Button>
            <div className="link-backtostore" onClick={BackToStore}>
                <img
                    className="logo"
                    src={`${storeConfig.secure_base_media_url}logo/${storeConfig.header_logo_src}`}
                    alt="logo"
                />
            </div>
            <style jsx>
                {`
                 #header {
                    height: 170px;
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;

                    width: 100%;
                    flex: 1;
                    position: relative;
                }

                #header .back-button {
                    position: absolute;
                    left: 0;
                    width: fit-content;
                }

                #header img {
                    cursor: pointer;
                    max-height: 125px;
                    width: auto;
                }

                .link-backtostore {
                    cursor: pointer;
                }
            `}
            </style>
        </div>
    );
};

export default HeaderCheckout;
